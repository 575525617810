<template>
  <div>
    <b-card v-if="details">
      <b-row>
        <b-col
          cols="12"
        >
          <div class="d-flex align-items-center justify-content-between">
            <h2 class="mb-2 text-primary">
              {{ $t('contact-details') }}
            </h2>
          </div>
          <hr class="mb-2">
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('name') }}
          </h6>
          <p>
            {{ details.name }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('email') }}
          </h6>
          <p>
            {{ details.email }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('phone') }}
          </h6>
          <p>
            {{ details.phone }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('type') }}
          </h6>
          <p>
            {{ details.type_value }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('created_at') }}
          </h6>
          <p>
            {{ formatDate(details.created_at) }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('status_value') }}
          </h6>
          <p>
            {{ details.status_value }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="12"
          lg="12"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('contact_message') }}
          </h6>
          <p>
            {{ details.message }}
          </p>
          <hr>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('response')"
                vid="response"
              >
                <b-form-group
                  :label="$t('response')"
                >
                  <b-form-textarea
                    v-model="form.response"
                    :placeholder="$t('response')"
                    autocomplete="off"
                    rows="5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="region_id"
                :name="$t('status')"
                rules="required"
              >
                <b-form-group
                  :label="$t('status')"
                >
                  <v-select
                    v-model="form.status"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name"
                    :options="statuses"
                    :reduce="item => item.id"
                    :placeholder="$t('status')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      details: null,
      form: {
        response: null,
        status: null,
      },
    }
  },
  computed: {
    statuses() {
      return [
        {
          id: 'pending',
          name: this.$t('statuses.pending'),
        },
        {
          id: 'read',
          name: this.$t('statuses.read'),
        },
        {
          id: 'replied',
          name: this.$t('statuses.replied'),
        },
        {
          id: 'closed',
          name: this.$t('statuses.closed'),
        },
      ]
    },
  },
  created() {
    this.getDetails()
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    getDetails() {
      this.axios.get(`/contact-us/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data
        })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${day < 10 ? `0${day}` : day}-${month < 10 ? `0${month}` : month}-${year}`
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            response: data.question,
            status: data.status,
          }
        })
    },
  },

}
</script>
